<template>
  <div :style="f_calculateDivStyle()">
    <!-- <div style="background: white; min-height: 750px; width: 100%;"> -->
    <template v-if="d_showModal">
      <wisdom-data-modal ref="wisdom_data_modal" :data="d_wisdomData.wisdom_data" :data_type="d_wisdomData.data_type" :option_data="d_wisdomData.option_data" :wdmr_id="wdmr_id" :layer_id="layer_id" :p_dmpWdmrId="dmp_wdmr_id" :p_dmpLayerId="dmp_layer_id" :p_wdmrSettings="d_wdmrSettings" :p_saveBehaviour="d_saveBehaviour" :wdmr_token="wdmr_token" :p_modelId="model_id" :p_schemaId="schema_id" :layer_data="layer_data" :p_clientSchemaId="client_schema_id"></wisdom-data-modal>
    </template>
    <template v-else>
      <b-row v-if="d_loadingText">
        <b-col cols="12" style="text-align: center;">
          {{ d_loadingText }}
        </b-col>
      </b-row>
      <template v-if="d_errorData.show">
        <b-row>
          <b-col cols="12" style="text-align: center; font-size: 100px;">
            Oops!
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" style="text-align: center;">
            <img src="@/icon/4035067.png" class="img-rounded img-responsive" style="width: 200px;" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" style="text-align: center; font-size: 40px;">
            {{ d_errorData.text }}
          </b-col>
        </b-row>
      </template>
    </template>
  </div>
</template>

<script>
import WmanagerService from '@/services/wmanager';
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import { mapGetters } from 'vuex';
// import Vue from 'vue';
// import { API_BASE_URL } from '@/config';
import store from '@/store';
import {
  default as router
} from '@/router';

export default {
  name: 'FillForm',
  computed: {
    ...mapGetters({})
  },
  components: {
    WisdomDataModal
  },
  data () {
    return {
      fill_form_layer_user_client_id_list: {},
      w_id_list: [],
      layer_data: {},
      layer_access_token: '',
      model_id: '',
      client_schema_id: '',
      schema_id: '',
      d_errorData: {
        'text': '',
        'show': false,
      },
      d_loadingText: '',
      d_showModal: false,
      layer_id: '',
      dmp_layer_id: '',
      wdmr_token: '',
      wdmr_id: '',
      dmp_wdmr_id: '',
      d_selectedWdmrData: '',
      d_saveBehaviour: {
        'type': 'manuel',
      },
      d_wisdomData: {
        'view_mode': 'table',
        'change': '0',
        'show': false,
        'wisdom_data': {},
        'data_type': '',
        'wisdom_op': '',
        'option_data': {}
      },
      d_wdmrSettings: {
        'wisdom_param': {
          'settings': 0,
          'mode': 1, // 1 normal, 2 label value mode up down parameter, 3 label value left to right,
          'user_style': 0,
          'style': {
            'caption': {
              'fontSize': 12,
              'color': 'black',
              'after': '',
              'backgroundColor': 'white',
              'border': {
                'type': 'solid',
                'size': 0,
                'color': 'black'
              },
              'borderRadius': 0
            },
            'value': {
              'fontSize': 10,
              'color': 'black',
              'after': '',
              'backgroundColor': 'white',
              'border': {
                'type': 'solid',
                'size': 0,
                'color': 'black'
              },
              'borderRadius': 0
            }
          }
        },
        'wdm_designer': {
          'show': 0,
          'settings': 0,
          'table': []
        },
        'right_page_mode': {
          'page': ''
        },
        'column_settings': {
          'cols': 1
        },
        'wdmr_schema': {
          'wdm_anatomy': {
            'show': 0,
            'view_mode': 'basic'
          },
          'wdm': {
            'show': 0,
            'view_mode': 'basic'
          }
        },
        'bmi_gfr_widget': {
          'show_mode': 'left_right'
        },
        'page_divide': 1,
        'settings': {
          'show': 0
        },
        'anatomy_component': {
          'mode': 2,
        },
        'anatomy_group': {
          'cols': 1
        },
        'favorite_show_mode': 0,
        'group': {
          'show': 1,
          'mode': 2
        },
        'date': {
          'show_as_row': 0
        },
        'wdmr_header_mode': 5, // 1 or 2 or 3 or 4
        'document_upload': {
          'show': 0
        }
      },
    };
  },
  created: function () {
    let x = localStorage.getItem('fill_form_layer_user_client_id_list');
    if (x) {
      this.fill_form_layer_user_client_id_list = JSON.parse(x);
    }
  },
  mounted: function () {
    if (this.$route.query.layer_id !== undefined) {
      this.layer_id = this.$route.query.layer_id;
    }
    if (this.$route.query.w_id_list !== undefined) {
      this.w_id_list = this.$route.query.w_id_list.split(',');
    }
    if (this.$route.query.lat !== undefined) {
      this.layer_access_token = this.$route.query.lat;
    }
    if (this.$route.query.dmp_layer_id !== undefined) {
      this.dmp_layer_id = this.$route.query.dmp_layer_id;
    }
    if (this.$route.query.wdmr_token !== undefined && this.$route.query.wdmr_token !== '') {
      this.wdmr_token = this.$route.query.wdmr_token;
    }
    if (this.$route.query.wdmr_id !== undefined) {
      this.wdmr_id = this.$route.query.wdmr_id;
    }
    if (this.$route.query.dmp_wdmr_id !== undefined) {
      this.dmp_wdmr_id = this.$route.query.dmp_wdmr_id;
    }
    if (this.$route.query.model_id !== undefined) {
      this.model_id = this.$route.query.model_id;
    }
    if (this.$route.query.schema_id !== undefined) {
      this.schema_id = this.$route.query.schema_id;
    }
    if (this.$route.query.client_schema_id !== undefined) {
      this.client_schema_id = this.$route.query.client_schema_id;
    }
    if (this.$route.query.record_type !== undefined) {
      if (['auto', 'manuel'].indexOf(this.$route.query.record_type) !== -1) {
        this.d_saveBehaviour.type = this.$route.query.record_type;
      }
    }
    if (this.$route.query.screen_mode !== undefined) {
      let screen_mode = 1;
      try {
        screen_mode = parseInt(this.$route.query.screen_mode);
      } catch (err) {}
      this.d_wdmrSettings.wisdom_param.mode = screen_mode;
    }
    this.f_getWdmrData();
  },
  methods: {
    f_calculateDivStyle: function () {
      let style = '';
      if (this.d_errorData.show) {
        style = 'background: #feedd7; height:' + window.innerHeight.toString() + 'px';
      }
      return style;
    },
    f_getWdmrData: function () {
      if (this.wdmr_id && this.layer_id) {
        this.d_loadingText = 'Veri modülünüz hazırlanıyor...';
        let query = 'layer_id=' + this.layer_id;
        query += '&wdmr_id=' + this.wdmr_id;
        // console.log(query);
        if (this.wdmr_token) {
          query += '&wdmr_token=' + this.wdmr_token;
        }
        WmanagerService.get_layer_wdmr_and_wdm(query)
          .then(resp => {
            if (resp.data.status_code === "3000") {
              this.d_wisdomData.wisdom_data = resp.data.wdmr;
              this.d_wisdomData.data_type = this.d_wisdomData.wisdom_data.type;
              this.d_wisdomData.option_data[this.d_wisdomData.data_type] = resp.data.layer_wdm;
              let query = 'layer_id=' + this.layer_id;
              if (this.wdmr_id) {
                query += '&wdmr_id=' + this.wdmr_id;
              }
              if (this.wdmr_token) {
                query += '&wdmr_token=' + this.wdmr_token;
              }
              WmanagerService.layer_data_get(query)
                .then(resp => {
                  if (resp.data.status_code === "3000") {
                    this.layer_data = resp.data.layer_data;
                  } else {
                    this.d_loadingText = '';
                    this.d_errorData.show = true;
                    this.d_errorData.text = resp.data.status_message + ' (' + resp.data.status_code + ')';
                    console.log('errorr : ', resp.data);
                  }
                });
              this.d_showModal = true;
            } else {
              this.d_loadingText = '';
              this.d_errorData.show = true;
              this.d_errorData.text = resp.data.status_message + ' (' + resp.data.status_code + ')';
              console.log('errorr : ', resp.data);
            }
          });
      } else if (this.layer_id) {
        // this.f_createNewWdmr();
        this.f_createNewWdmrAndToken();
      } else {
        this.d_errorData.show = true;
        this.d_errorData.text = 'Please define your wdmr ID or layer ID.';
      }
    },
    f_createNewWdmrAndToken: function () {
      if (this.fill_form_layer_user_client_id_list[this.layer_id]) {
        let query = 'layer_id=' + this.layer_id;
        let client_id = this.fill_form_layer_user_client_id_list[this.layer_id];
        query += '&client_id=' + client_id;
        if (this.layer_access_token) {
          query += '&lat=' + this.layer_access_token;
        }
        let new_wdmr_data = { 'data': {} };
        let data = {
          'wdmr': new_wdmr_data,
        };
        // this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12709') } });
        WmanagerService.layer_wdmr_token(query, data)
          .then(resp => {
            // console.log("resp.data : ", resp.data);
            if (resp.data.status_code === '3000') {
              this.wdmr_id = resp.data.w_id;
              this.wdmr_token = resp.data.wdmr_token;
              this.f_getWdmrData();
            } else {
              this.d_loadingText = '';
              this.d_errorData.show = true;
              this.d_errorData.text = resp.data.status_message + ' (' + resp.data.status_code + ')';
              console.log('errorr : ', resp.data);
            }
          })
      } else {
        WmanagerService.get_request_ip()
          .then(resp => {
            // console.log("resp.data : ", resp.data);
            if (resp.data.status_code === '3000') {
              let query = 'layer_id=' + this.layer_id;
              let client_id = resp.data.request_ip.replace(/\./g, '_').replace(/\:/g, '_');
              let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
              let charactersLength = chars.length;
              let randomString = '';
              for (let i = 0; i < 33; i++) {
                let random_integer = Math.floor(Math.random() * charactersLength);
                if (random_integer < charactersLength) {
                  randomString += chars[random_integer];
                }
              }
              client_id += '_' + randomString;
              this.fill_form_layer_user_client_id_list[this.layer_id] = client_id;
              localStorage.setItem('fill_form_layer_user_client_id_list', JSON.stringify(this.fill_form_layer_user_client_id_list));
              query += '&client_id=' + client_id;
              if (this.layer_access_token) {
                query += '&lat=' + this.layer_access_token;
              }
              let new_wdmr_data = { 'data': {} };
              let data = {
                'wdmr': new_wdmr_data,
              };
              // this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12709') } });
              WmanagerService.layer_wdmr_token(query, data)
                .then(resp => {
                  // console.log("resp.data : ", resp.data);
                  if (resp.data.status_code === '3000') {
                    this.wdmr_id = resp.data.w_id;
                    this.wdmr_token = resp.data.wdmr_token;
                    this.f_getWdmrData();
                  } else {
                    this.d_loadingText = '';
                    this.d_errorData.show = true;
                    this.d_errorData.text = resp.data.status_message + ' (' + resp.data.status_code + ')';
                    console.log('errorr : ', resp.data);
                  }
                })
            } else {
              this.d_loadingText = '';
              this.d_errorData.show = true;
              this.d_errorData.text = resp.data.status_message + ' (' + resp.data.status_code + ')';
              console.log('errorr : ', resp.data);
            }
          });
      }
    },
  }
};

</script>

